//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { error500 } from '../../static/images/index';
export default {
    name: 'Error500',
    props: ['error'],
    data() {
        return { error500 };
    },
    methods: {
        reload() {
            window.location.reload(true);
        }
    }
};
