export default (http, resource, apiVersion) => ({
    submitAnswer(testTakerId, questionId, data) {
        const method = 'put';
        const url = `${resource}/${apiVersion}/${testTakerId}/evaluate/${questionId}`;
        return http({ method, url, data });
    },
    pauseAndSaveTest(testTakerId, testSectionInstanceId, data = null) {
        const method = 'put';
        const url = `${resource}/${apiVersion}/${testTakerId}/save-and-exit-test/${testSectionInstanceId}`;
        return http({ method, url, data });
    },
    exitTest(testTakerId, testSectionInstanceId) {
        const method = 'put';
        const url = `${resource}/${apiVersion}/${testTakerId}/exit-test/${testSectionInstanceId}`;
        return http({ method, url });
    },
    exitSectionOnTimerExpiry(testTakerId, testSectionInstanceId) {
        const method = 'put';
        const url = `${resource}/${apiVersion}/${testTakerId}/exit-test-on-timer-expiry/${testSectionInstanceId}`;
        return http({ method, url });
    }
});
