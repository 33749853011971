export default (http, resource, apiVersion) => ({
    getLoggedInUser() {
        const method = 'get';
        const url = `${resource}/${apiVersion}/get-logged-in-user`;
        const headers = {
            'Cache-Control': 'no-store'
        };
        return http({ method, url, headers });
    },
    updateUserAction(data) {
        const method = 'post';
        const url = `${resource}/${apiVersion}/update-user-actions`;
        return http({ method, url, data });
    },
    updateProfile(data) {
        const method = 'put';
        const url = `${resource}/${apiVersion}/update-profile`;
        return http({ method, url, data });
    },
    fetchUserFreebies() {
        const method = 'get';
        const url = `${resource}/${apiVersion}/user-freebies`;
        return http({ method, url });
    }
});
