export default (http, resource, apiVersion) => ({
    getAllPremiumPlans({ couponCode, planSlug }) {
        const method = 'get';
        let url = `${resource}/${apiVersion}/plans/`;
        if (couponCode && planSlug) {
            url = `${resource}/${apiVersion}/plans?plan_key=${planSlug}&coupon_code=${couponCode}`;
        }

        return http({ method, url });
    },
    getSinglePlanDetails(planSlug) {
        const method = 'get';
        const url = `${resource}/${apiVersion}/plans/${planSlug}`;
        return http({ method, url });
    },
    validateCoupon(data) {
        const method = 'post';
        const url = `${resource}/${apiVersion}/validate-coupon`;
        return http({ method, url, data });
    },
    createPremiumOrder(data) {
        const method = 'post';
        const url = `${resource}/${apiVersion}/create-payment-order`;
        return http({ method, url, data });
    },
    confirmOrderSuccess(data) {
        const method = 'post';
        const url = `${resource}/${apiVersion}/confirm-order-status`;
        return http({ method, url, data });
    },
    getSubscriptionOrderDetails() {
        const method = 'get';
        const url = `${resource}/${apiVersion}/subscription-details`;
        return http({ method, url });
    },
    createFreePremiumOrder(data) {
        const method = 'post';
        const url = `${resource}/${apiVersion}/free-prep-plus-order`;
        return http({ method, url, data });
    },
    getUserReferralDetails() {
        const method = 'get';
        const url = `${resource}/${apiVersion}/user-referral-details`;
        return http({ method, url });
    },
    continueExpiredSubscription() {
        const method = 'post';
        const url = `${resource}/${apiVersion}/continue-expired-subscription`;
        return http({ method, url });
    }
});
