export default (http, resource, apiVersion) => ({
    sendOtp(data) {
        const method = 'post';
        const url = `${resource}/${apiVersion}/send-otp`;
        return http({ method, url, data });
    },
    verifyOtp(data) {
        const method = 'post';
        const url = `${resource}/${apiVersion}/verify-otp`;
        return http({ method, url, data });
    },
    signup(data) {
        const method = 'post';
        const url = `${resource}/${apiVersion}/signup`;
        return http({ method, url, data });
    },
    validateContact(data) {
        const method = 'post';
        const url = `${resource}/${apiVersion}/validate/contact`;
        return http({ method, url, data });
    },
    logout() {
        const method = 'post';
        const url = `${resource}/${apiVersion}/logout`;
        return http({ method, url });
    },
    getCountriesList() {
        const method = 'get';
        const url = `countries/v1`;
        return http({ method, url });
    },
    saveUserDetails(data) {
        const method = 'post';
        const url = `${resource}/v2/local/social-signin/save-data`;
        return http({ method, url, data });
    },
    indianSocialSignIn(data) {
        const method = 'post';
        const url = `${resource}/v2/local/social-signin`;
        return http({ method, url, data });
    },
    internationalSocialSignin(data) {
        const method = 'post';
        const url = `${resource}/v2/international/social-signin`;
        return http({ method, url, data });
    }
});
