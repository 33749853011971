//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Loader',
    props: {
        type: {
            type: String,
            default: 'full'
        },
        background: {
            type: String,
            default: '#fcfcfc'
        },
        size: {
            type: String,
            default: 'sm'
        },
        color: {
            type: String,
            default: 'white'
        },
        marginClass: {
            type: String,
            default: '-mt-16'
        }
    },
    computed: {
        spinnerSizeClass() {
            switch (this.size) {
                case 'sm':
                    return `h-5 w-5 border-2`;
                case 'md':
                    return `h-10 w-10 border-4`;
                case 'lg':
                    return `h-14 w-14 border border-[6px]`;
                default:
                    return '';
            }
        },
        spinnerColorClass() {
            switch (this.color) {
                case 'primary':
                    return `border-theme-primary border-t-transparent`;
                case 'white':
                    return `border-white border-t-transparent`;
                default:
                    return '';
            }
        },
        ellipseSizeClass() {
            switch (this.size) {
                case 'sm':
                    return `h-1.5 w-1.5`;
                case 'md':
                    return `h-2 w-2`;
                case 'lg':
                    return `h-3 w-3`;
                default:
                    return '';
            }
        },
        ellipseColorClass() {
            switch (this.color) {
                case 'white':
                    return `bg-white`;
                case 'primary':
                    return `bg-theme-primary`;
                default:
                    return '';
            }
        }
    }
};
