//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Icon',
    props: {
        src: {
            type: String,
            default: '',
            required: true
        },
        alt: {
            type: String,
            default: '',
            required: true
        },
        height: {
            type: String,
            default: 'auto'
        },
        width: {
            type: String,
            default: 'auto'
        },
        optimize: {
            type: Boolean,
            default: false
        },
        quality: {
            type: Number,
            default: 50
        },
        sizes: {
            type: String,
            default: ''
        },
        loading: {
            type: String,
            default: ''
        }
    }
};
