//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { error404 } from '../../static/images/index';
import { ROUTES } from '~/constants/pageRoutes';

export default {
    name: 'Error404',
    // eslint-disable-next-line vue/require-prop-types
    props: ['error'],
    data() {
        return { ROUTES, error404 };
    }
};
