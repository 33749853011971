export const PRACTICE_ROUNDS_INIT_DATA = [
    {
        id: 1,
        label: 'A',
        heading: 'Read',
        subheading: 'Read and comprehend words',
        completionText: 'Great Job!',
        showAnswerResponse: false,
        isTestBasedRound: false,
        questionCount: 0,
        isComplete: false,
        questions: []
    },
    {
        id: 2,
        label: 'B',
        heading: 'Quiz- I',
        subheading: 'Recall words that you just read',
        completionText: 'You’ve came a long way!',
        showAnswerResponse: true,
        isTestBasedRound: true,
        questionCount: 0,
        isComplete: false,
        questions: []
    },
    {
        id: 3,
        label: 'C',
        heading: 'Quiz- II',
        subheading: 'Boosts your long term memory',
        completionText: '',
        showAnswerResponse: true,
        isTestBasedRound: true,
        questionCount: 0,
        isComplete: false,
        questions: []
    }
];

export const DAY_COMPLETION_STATE = {
    DISABLED: 0,
    ZERO_COMPLETION: 1,
    HALF_COMPLETION: 2,
    FULL_COMPLETION: 3
};
