export const LIVE_CLASS_DURATIONS = {
    // Dates will now come from API
    COURSE_DURATION: '2 Months',
    TIMINGS: '8:00 - 9:30 PM IST'
};

export const COMPETITORS_PRICE = {
    MAGOOSH: { INR: 39000, USD: 479 },
    KAPLAN: { INR: 82000, USD: 999 },
    MANHATTAN_PREP: { INR: 115000, USD: 1399 }
};

export const FREE_TRIAL_VALIDITY = 3;
