export default (http, resource, apiVersion) => ({
    getTestSets(queryParams) {
        const method = 'get';
        const url = `${resource}/${apiVersion}`;
        return http({ method, url, params: queryParams });
    },
    getTestSetUsingId(id) {
        const method = 'get';
        const url = `${resource}/${apiVersion}/${id}`;
        return http({ method, url });
    }
});
