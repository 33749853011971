export default (http, resource, apiVersion) => ({
    getFeedbackConfig() {
        const method = 'get';
        const url = `${resource}/${apiVersion}/get-feedback-config`;
        return http({ method, url });
    },
    submitFeedback(testTakerId, questionId, data) {
        const method = 'post';
        const url = `${resource}/${apiVersion}/${testTakerId}/submit-feedback/${questionId}`;
        return http({ method, url, data });
    }
});
