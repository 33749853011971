export default (http, resource, apiVersion) => ({
    getPlannerData(data = null) {
        const method = 'post';
        const url = `${resource}/${apiVersion}/get-planner`;
        return http({ method, url, data });
    },

    /**
     * @param {test_date: timestamp, focus_subject: number | undefined} data
     * @returns Object
     */
    getPlannerDataV2(data = null) {
        const method = 'post';
        const url = `${resource}/v2/get-planner`;
        return http({ method, url, data });
    },
    /**
     * @param {task_id: Number, task_type: String, is_completed: Boolean} data
     */
    updateTaskStatus(data = null) {
        const method = 'put';
        const url = `${resource}/${apiVersion}/update-task-status`;
        return http({ method, url, data });
    }
});
