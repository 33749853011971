// Indian User
// Phone => Phone Number > Otp verification > User details > Create Account
// Google => Google Login > User details (incl. phone) > Otp verification > Create account

// International user -
// Email => Email > Otp verification > User Details > Create Account
// Google => Google login > User Details > Create Account

const LOGIN_METHOD = {
    PHONE: 'phone',
    EMAIL: 'email',
    SOCIAL: 'social'
};

const AUTH_FLOW = {
    INDIAN_PHONE: 'INDIAN_PHONE',
    INDIAN_GOOGLE: 'INDIAN_GOOGLE',
    INTERNATIONAL_PHONE: 'INTERNATIONAL_PHONE',
    INTERNATIONAL_EMAIL: 'INTERNATIONAL_EMAIL',
    INTERNATIONAL_GOOGLE: 'INTERNATIONAL_GOOGLE'
};

const AUTH_STEP = {
    PHONE_INPUT: 'PHONE_INPUT',
    EMAIL_INPUT: 'EMAIL_INPUT',
    GOOGLE_SIGNIN: 'GOOGLE_SIGNIN',
    OTP_VERIFICATION: 'OTP_VERIFICATION',
    USER_AND_GRE_DETAILS: 'USER_AND_GRE_DETAILS',
    GRE_DETAILS: 'GRE_DETAILS'
};

const INDIAN_PHONE = {
    authFlow: AUTH_FLOW.INDIAN_PHONE,
    authSteps: [
        {
            stepCount: 0,
            stepName: AUTH_STEP.PHONE_INPUT
        },
        {
            stepCount: 1,
            stepName: AUTH_STEP.OTP_VERIFICATION
        },
        {
            stepCount: 2,
            stepName: AUTH_STEP.USER_AND_GRE_DETAILS
        }
    ]
};

const INDIAN_GOOGLE = {
    authFlow: AUTH_FLOW.INDIAN_GOOGLE,
    authSteps: [
        {
            stepCount: 0,
            stepName: AUTH_STEP.GOOGLE_SIGNIN
        },
        {
            stepCount: 1,
            stepName: AUTH_STEP.GRE_DETAILS
        },
        {
            stepCount: 2,
            stepName: AUTH_STEP.OTP_VERIFICATION
        }
    ]
};

const INTERNATIONAL_PHONE = {
    authFlow: AUTH_FLOW.INTERNATIONAL_PHONE,
    authSteps: [
        {
            stepCount: 0,
            stepName: AUTH_STEP.PHONE_INPUT
        },
        {
            stepCount: 1,
            stepName: AUTH_STEP.OTP_VERIFICATION
        },
        {
            stepCount: 2,
            stepName: AUTH_STEP.USER_AND_GRE_DETAILS
        }
    ]
};

const INTERNATIONAL_EMAIL = {
    authFlow: AUTH_FLOW.INTERNATIONAL_EMAIL,
    authSteps: [
        {
            stepCount: 0,
            stepName: AUTH_STEP.EMAIL_INPUT
        },
        {
            stepCount: 1,
            stepName: AUTH_STEP.OTP_VERIFICATION
        },
        {
            stepCount: 2,
            stepName: AUTH_STEP.USER_AND_GRE_DETAILS
        }
    ]
};

const INTERNATIONAL_GOOGLE = {
    authFlow: AUTH_FLOW.INTERNATIONAL_GOOGLE,
    authSteps: [
        {
            stepCount: 0,
            stepName: AUTH_STEP.GOOGLE_SIGNIN
        },
        {
            stepCount: 1,
            stepName: AUTH_STEP.GRE_DETAILS
        }
    ]
};

export {
    LOGIN_METHOD,
    AUTH_FLOW,
    AUTH_STEP,
    INDIAN_PHONE,
    INDIAN_GOOGLE,
    INTERNATIONAL_PHONE,
    INTERNATIONAL_EMAIL,
    INTERNATIONAL_GOOGLE
};
