//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ModalContainer',
    props: {
        // if centerPositioned, the modal won't come down on pwa as a drawer.
        centerPositioned: {
            type: Boolean,
            default: false
        },
        blurBg: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        document.body.style.overflow = 'hidden';
    },
    beforeDestroy() {
        document.body.style.overflow = 'auto';
    }
};
