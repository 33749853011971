export default (http, resource, apiVersion) => ({
    getNewSectionQuestions(testSetId, data) {
        const method = 'post';
        const url = `${resource}/${apiVersion}/get-questions/${testSetId}`;
        return http({ method, url, data });
    },
    getResumedSectionQuestions(testTakerId) {
        const method = 'get';
        const url = `${resource}/${apiVersion}/${testTakerId}/get-questions-for-resume`;
        return http({ method, url });
    }
});
