export default (http, resource, apiVersion) => ({
    getDashboard() {
        const method = 'get';
        const url = `${resource}/${apiVersion}/get-dashboard`;
        return http({ method, url });
    },
    startPracticeSet(practiceSetId) {
        const method = 'get';
        const url = `${resource}/${apiVersion}/start-practice-set?practice_set_id=${practiceSetId}`;
        return http({ method, url });
    },
    fetchQuizQuestionSet({ questionSetId, practiceSessionId }) {
        const method = 'get';
        const url = `${resource}/${apiVersion}/get-questions/${questionSetId}?test_taker_id=${practiceSessionId}`;
        return http({ method, url });
    },
    submitAnswer({ practiceSessionId, questionId, data }) {
        const method = 'put';
        const url = `${resource}/${apiVersion}/${practiceSessionId}/evaluate/${questionId}`;
        return http({ method, url, data });
    },
    submitSection(practiceSessionId, data) {
        const method = 'put';
        const url = `${resource}/${apiVersion}/${practiceSessionId}/submit-section`;
        return http({ method, url, data });
    },
    exitPracticeSet(practiceSessionId) {
        const method = 'put';
        const url = `${resource}/${apiVersion}/${practiceSessionId}/exit-test`;
        return http({ method, url });
    },
    getReport(practiceSessionId) {
        const method = 'post';
        const url = `${resource}/${apiVersion}/${practiceSessionId}/get-report-data`;
        return http({ method, url });
    },
    getReviewWordList(confidenceLevel) {
        const method = 'get';
        const url = `${resource}/${apiVersion}/words-review/${confidenceLevel}`;
        return http({ method, url });
    },
    getSingleWordDetail(wordIds) {
        let commaSeparatedIds = wordIds;
        if (Array.isArray(wordIds)) {
            commaSeparatedIds = wordIds.join(',');
        }

        const method = 'get';
        const url = `${resource}/${apiVersion}/words?word_ids=${commaSeparatedIds}`;
        return http({ method, url });
    }
});
